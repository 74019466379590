// import packages
import { useState, useEffect } from 'react';
import { motion } from 'motion/react';

// import assets
import pdf from '../../../public/assets/wolf_q2_2025.jpg';

// Updated SignUpPage with improved animations
export const ReportSignUp = () => {
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [message, setMessage] = useState('');

	useEffect(() => {
		let timeout;
		if (message || errorMessage) {
			// Clear the message and success state after 5 seconds
			timeout = setTimeout(() => {
				setMessage('');
				setEmail('');
				setErrorMessage('');
			}, 5000);
		}

		// Cleanup function to clear the timeout if the component unmounts
		return () => clearTimeout(timeout);
	}, [message, errorMessage]);

	const handleSignup = async () => {
		try {
			// Send the email to the backend API
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/signup`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			// data from server
			const data = await response.json();

			if (response.ok) {
				// Clear the email field and set success message
				setEmail('');
				setMessage(data.message || 'Thank you for signing up! Please check your email to verify your subscription.');
			} else {
				setErrorMessage(data.message || 'Error during sign-up. Please try again.');
			}
		} catch (error) {
			setErrorMessage('An error occurred. Please try again.');
			console.error(error);
		}
	};

	const renderMessage = () => {
		if (errorMessage) {
			return (
				<motion.div
					className="w-full px-4 py-4 rounded-lg drop-shadow-lg bg-red-500"
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					transition={{ duration: 0.5, ease: 'easeOut' }}>
					<p className="text-zinc-900 maintext tracking-wide text-center font-semibold">{errorMessage}</p>
				</motion.div>
			);
		} else if (message) {
			return (
				<motion.div
					className="w-full px-4 py-4 rounded-lg drop-shadow-lg bg-luif-cta-2"
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					transition={{ duration: 0.5, ease: 'easeOut' }}>
					<p className="text-zinc-900 maintext tracking-wide text-center font-semibold">{message}</p>
				</motion.div>
			);
		}
	};

	return (
		<div>
			<div className="bg-gray-50 text-zinc-900">
				<div className="text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-4 sm:px-32">
					<div></div>
					<div className="sm:col-start-2 sm:col-span-4 py-12 space-y-2">
						<motion.div
							initial={{ y: 50, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ type: 'spring', stiffness: 100, damping: 20 }}
							className=" py-8 px-4  rounded-xl space-y-4">
							<div>
								<motion.p
									initial={{ opacity: 0, scale: 0.8 }}
									animate={{ opacity: 1, scale: 1 }}
									transition={{ delay: 0.5, duration: 1.05 }}
									className="subText font-black text-[28px] text-center">
									Get Up to Speed on WolfSpeed’s Latest 10-Q
								</motion.p>

								<div className="h-full w-full sm:h-4/6 sm:w-5/6 mx-auto px-4">
									<img src={pdf} />
								</div>

								<div className="text-[16px] tracking-wide">
									<p>WolfSpeed just dropped their latest SEC 10-Q filing—but who has time to dig through all that?</p>
									<p className="font-black">We did the work for you.</p>
								</div>

								<div className="text-[16px] font-normal">
									<p>✅ Key insights, financial trends, and takeaways</p>
									<p>✅ No fluff—just the data that matters</p>
									<p>✅ Completely free—instant access</p>
								</div>

								<p className="text-[16px]">Download our breakdown now and stay ahead of the game.</p>
							</div>

							<div>
								<p className="logo uppercase font-black text-[12px]">Email</p>
								<motion.input
									type="email"
									placeholder="example@example.com"
									value={email}
									onChange={e => setEmail(e.target.value)}
									required
									className="px-4 py-2 pl-4 rounded-lg w-full text-zinc-800"
									onKeyDown={e => {
										if (e.key === 'Enter') {
											handleSignup();
										}
									}}
									initial={{ scale: 1 }}
									whileFocus={{
										scale: 1.01,
										boxShadow: '0px 0px 15px #d9f99d',
									}}
									transition={{
										type: 'spring',
										stiffness: 200,
										damping: 15,
									}}
								/>
							</div>

							{renderMessage()}

							<motion.div
								whileHover={{ scale: 0.99 }}
								whileTap={{
									scale: 1,
									transition: { type: 'spring', stiffness: 300, damping: 15 },
								}}
								onClick={() => handleSignup()}
								className="rounded-xl bg-luif-cta-2 text-luif-bg px-4 py-2 text-[16px] logo font-semibold text-center w-full cursor-pointer">
								Get the Free Breakdown
							</motion.div>
						</motion.div>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
};
