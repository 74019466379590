// import packages
import { useEffect, useState } from 'react';

// import components
import { Navbar } from '../navbar/index.jsx';
import { Footer } from '../footer/index.jsx';
import { StockChartLoading } from '../stock_chart_loading/index.jsx';

export const Report001 = () => {
	// Helper function to store token in localStorage
	const storeToken = token => {
		localStorage.setItem('userToken', token);
	};

	// Helper function to remove token from localStorage
	const removeToken = () => {
		localStorage.removeItem('userToken');
	};

	// Helper function to get token from localStorage
	const getToken = () => {
		localStorage.getItem('userToken');
	};

	// Helper function to check token expiration
	const isTokenExpired = token => {
		try {
			const payload = JSON.parse(atob(token.split('.')[1]));
			const currentTime = Math.floor(Date.now() / 1000);
			return payload.exp < currentTime;
		} catch {
			return true;
		}
	};

	// Extract and store token from URL
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('token');

		if (token) {
			storeToken(token);
		}
	}, [window.location.search, window.location.href]);

	const downloadReport = async () => {
		const token = window.localStorage.getItem('userToken');

		if (!token || isTokenExpired(token)) {
			// removeToken();
			// window.location.replace('/not-authorized');
			return;
		}

		try {
			const settings = {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			};

			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/download-report`, settings);
			if (!response.ok) {
				console.error('Report not downloaded:', response);
				throw new Error('Report not downloaded.');
			}

			// Convert the response into a Blob
			const blob = await response.blob();
			// Create a URL for the blob and simulate a click to download the file
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'lvlupinfinance_wolf_q2_2025.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Failed to download report:', error);
			// Optionally remove the token or redirect the user on failure
		}
	};

	return (
		<div>
			<Navbar />
			<div className="text-slate-50 logo my-16 text-left grid grid-cols-1 sm:grid-cols-6 mx-auto px-2 sm:px-32">
				<div></div>
				<div className="sm:col-start-2 sm:col-span-4 space-y-4">
					<h1 className="text-3xl">You're All Set!</h1>
					<p className="text-md">
						Thank you for verifying your email and joining our community of finance enthusiasts. Your exclusive breakdown of WolfSpeed’s latest 10-Q is ready to
						download!
					</p>

					<div className="">
						<button onClick={() => downloadReport()} className="bg-luif-cta-2 px-4 py-2 rounded-md text-zinc-900 logo font-semibold">
							Download Now
						</button>
					</div>

					<p>We’re excited to have you on board and can’t wait to share more insights and reports with you soon.</p>
					<p>Happy reading!</p>
					<hr />
					<p className="text-sm text-center text-gray-400 mt-4">
						If you're having trouble downloading your report, please contact us at lvlupinfinance@gmail.com to report your issue.
					</p>
				</div>
				<div></div>
			</div>
			<Footer />
		</div>
	);
};
