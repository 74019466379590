// import packahes
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

// import context
import { PodcastProvider } from '../src/context/audio_context.js';

// import components
import { App } from './app.js';

// import hooks
import { trackPageVisit } from '../src/hooks/useAnalytics.jsx';

// import pages
import { Tos } from './pages/tos.jsx';
import { Privacy } from './pages/pp.jsx';
import { NotAuthorizedPage } from './pages/not_authorized.jsx';
import { ReportsPage } from './pages/reports_page.jsx';
import { TAPage } from './pages/ta_page.jsx';
import { FormS1 } from './components/glossary/form_s1.jsx';
import { Report001 } from './components/downloads/report_001.jsx';

const container = document.getElementById('app');
const root = createRoot(container);

// Analytics Layout
const AnalyticsLayout = () => {
	trackPageVisit(); // Initialize analytics tracking
	return <Outlet />; // Render child routes
};

// inject react router
const router = createBrowserRouter(
	[
		{
			element: <AnalyticsLayout />, // Wrap all routes with AnalyticsLayout
			children: [
				{ path: '/', element: <App /> },
				{ path: '/terms-of-service', element: <Tos /> },
				{ path: '/privacy-policy', element: <Privacy /> },
				{ path: '/not-authorized', element: <NotAuthorizedPage /> },
				{ path: '/library', element: <ReportsPage /> },
				{ path: '/form-s-1', element: <FormS1 /> },
				{ path: '/oklo-ta-q1-2025', element: <TAPage /> },
				{ path: '/download_report_001', element: <Report001 /> },
				{ path: '*', element: <App /> },
			],
		},
	],
	{
		future: {
			v7_partialHydration: true, // Opt-in for v7 partial hydration
			v7_startTransition: true,
			v7_relativeSplatPath: true,
			v7_fetcherPersist: true,
			v7_normalizeFormMethod: true,
			v7_skipActionErrorRevalidation: true,
		},
	}
);

root.render(
	<div className="bg-gray-800 h-dvh w-dvw overflow-scroll">
		<PodcastProvider>
			<RouterProvider router={router} />
		</PodcastProvider>
	</div>
);
